@import url('../node_modules/wwg-utilities/styles/skillup/fonts.css');
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * apertura:
 *   - http://typekit.com/eulas/00000000000000003b9b298a
 *   - http://typekit.com/eulas/00000000000000003b9b298b
 *   - http://typekit.com/eulas/00000000000000003b9b298c
 *   - http://typekit.com/eulas/00000000000000003b9b298d
 *   - http://typekit.com/eulas/00000000000000003b9b298f
 *   - http://typekit.com/eulas/00000000000000003b9b298e
 *   - http://typekit.com/eulas/00000000000000003b9b2990
 *   - http://typekit.com/eulas/00000000000000003b9b2991
 * apertura-condensed:
 *   - http://typekit.com/eulas/00000000000000003b9b2992
 *   - http://typekit.com/eulas/00000000000000003b9b2993
 *   - http://typekit.com/eulas/00000000000000003b9b2996
 *   - http://typekit.com/eulas/00000000000000003b9b2997
 * chaparral-pro:
 *   - http://typekit.com/eulas/00000000000000003b9aee4e
 *   - http://typekit.com/eulas/00000000000000003b9aee4d
 *   - http://typekit.com/eulas/00000000000000003b9aee52
 *   - http://typekit.com/eulas/00000000000000003b9aee51
 *   - http://typekit.com/eulas/00000000000000003b9aee4b
 *   - http://typekit.com/eulas/00000000000000003b9aee4f
 * chaparral-pro-caption:
 *   - http://typekit.com/eulas/00000000000000003b9aee56
 *   - http://typekit.com/eulas/00000000000000003b9aee55
 *   - http://typekit.com/eulas/00000000000000003b9aee59
 *   - http://typekit.com/eulas/00000000000000003b9aee5a
 * chaparral-pro-display:
 *   - http://typekit.com/eulas/00000000000000003b9aee62
 *   - http://typekit.com/eulas/00000000000000003b9aee5d
 *   - http://typekit.com/eulas/00000000000000003b9aee5e
 *   - http://typekit.com/eulas/00000000000000003b9aee61
 * chaparral-pro-subhead:
 *   - http://typekit.com/eulas/00000000000000003b9aee66
 *   - http://typekit.com/eulas/00000000000000003b9aee69
 *   - http://typekit.com/eulas/00000000000000003b9aee6a
 *   - http://typekit.com/eulas/00000000000000003b9aee65
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2020-02-13 23:07:12 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=zhb6mex&ht=tk&f=608.609.610.611.612.613.614.615.616.617.620.621.6905.6906.6907.6908.6909.6911.6913.6914.6915.13390.6917.6919.13394.13395.6921.13384.13385.13386&a=6924201&app=typekit&e=css');

@font-face {
  font-family: 'apertura';
  src: url('https://use.typekit.net/af/a94c5e/00000000000000003b9b298a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/a94c5e/00000000000000003b9b298a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/a94c5e/00000000000000003b9b298a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'apertura';
  src: url('https://use.typekit.net/af/1618ee/00000000000000003b9b298b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/1618ee/00000000000000003b9b298b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/1618ee/00000000000000003b9b298b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'apertura';
  src: url('https://use.typekit.net/af/d9ccb7/00000000000000003b9b298c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d9ccb7/00000000000000003b9b298c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d9ccb7/00000000000000003b9b298c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'apertura';
  src: url('https://use.typekit.net/af/08b0ec/00000000000000003b9b298d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/08b0ec/00000000000000003b9b298d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/08b0ec/00000000000000003b9b298d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'apertura';
  src: url('https://use.typekit.net/af/f53128/00000000000000003b9b298f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/f53128/00000000000000003b9b298f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/f53128/00000000000000003b9b298f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'apertura';
  src: url('https://use.typekit.net/af/2bbd4b/00000000000000003b9b298e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/2bbd4b/00000000000000003b9b298e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/2bbd4b/00000000000000003b9b298e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'apertura';
  src: url('https://use.typekit.net/af/08dc22/00000000000000003b9b2990/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/08dc22/00000000000000003b9b2990/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/08dc22/00000000000000003b9b2990/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'apertura';
  src: url('https://use.typekit.net/af/1c2844/00000000000000003b9b2991/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/1c2844/00000000000000003b9b2991/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('woff'),
    url('https://use.typekit.net/af/1c2844/00000000000000003b9b2991/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

@font-face {
  font-family: 'apertura-condensed';
  src: url('https://use.typekit.net/af/559676/00000000000000003b9b2992/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/559676/00000000000000003b9b2992/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/559676/00000000000000003b9b2992/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'apertura-condensed';
  src: url('https://use.typekit.net/af/e7e7c5/00000000000000003b9b2993/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/e7e7c5/00000000000000003b9b2993/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/e7e7c5/00000000000000003b9b2993/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'apertura-condensed';
  src: url('https://use.typekit.net/af/cbe945/00000000000000003b9b2996/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/cbe945/00000000000000003b9b2996/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/cbe945/00000000000000003b9b2996/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'apertura-condensed';
  src: url('https://use.typekit.net/af/9e6245/00000000000000003b9b2997/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/9e6245/00000000000000003b9b2997/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/9e6245/00000000000000003b9b2997/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'chaparral-pro';
  src: url('https://use.typekit.net/af/9da37c/00000000000000003b9aee4e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/9da37c/00000000000000003b9aee4e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/9da37c/00000000000000003b9aee4e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'chaparral-pro';
  src: url('https://use.typekit.net/af/6d0ba6/00000000000000003b9aee4d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/6d0ba6/00000000000000003b9aee4d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/6d0ba6/00000000000000003b9aee4d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'chaparral-pro';
  src: url('https://use.typekit.net/af/20a4c3/00000000000000003b9aee52/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/20a4c3/00000000000000003b9aee52/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/20a4c3/00000000000000003b9aee52/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'chaparral-pro';
  src: url('https://use.typekit.net/af/4bba43/00000000000000003b9aee51/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/4bba43/00000000000000003b9aee51/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/4bba43/00000000000000003b9aee51/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'chaparral-pro';
  src: url('https://use.typekit.net/af/d07f20/00000000000000003b9aee4b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d07f20/00000000000000003b9aee4b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d07f20/00000000000000003b9aee4b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'chaparral-pro';
  src: url('https://use.typekit.net/af/17d530/00000000000000003b9aee4f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/17d530/00000000000000003b9aee4f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('woff'),
    url('https://use.typekit.net/af/17d530/00000000000000003b9aee4f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'chaparral-pro-caption';
  src: url('https://use.typekit.net/af/f30eab/00000000000000003b9aee56/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/f30eab/00000000000000003b9aee56/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/f30eab/00000000000000003b9aee56/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'chaparral-pro-caption';
  src: url('https://use.typekit.net/af/f82575/00000000000000003b9aee55/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/f82575/00000000000000003b9aee55/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/f82575/00000000000000003b9aee55/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'chaparral-pro-caption';
  src: url('https://use.typekit.net/af/411825/00000000000000003b9aee59/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/411825/00000000000000003b9aee59/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/411825/00000000000000003b9aee59/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'chaparral-pro-caption';
  src: url('https://use.typekit.net/af/b5e410/00000000000000003b9aee5a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b5e410/00000000000000003b9aee5a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b5e410/00000000000000003b9aee5a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'chaparral-pro-subhead';
  src: url('https://use.typekit.net/af/7e3795/00000000000000003b9aee66/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/7e3795/00000000000000003b9aee66/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/7e3795/00000000000000003b9aee66/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'chaparral-pro-subhead';
  src: url('https://use.typekit.net/af/be02f1/00000000000000003b9aee69/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/be02f1/00000000000000003b9aee69/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/be02f1/00000000000000003b9aee69/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'chaparral-pro-subhead';
  src: url('https://use.typekit.net/af/913150/00000000000000003b9aee6a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/913150/00000000000000003b9aee6a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/913150/00000000000000003b9aee6a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'chaparral-pro-subhead';
  src: url('https://use.typekit.net/af/23f94f/00000000000000003b9aee65/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/23f94f/00000000000000003b9aee65/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/23f94f/00000000000000003b9aee65/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'chaparral-pro-display';
  src: url('https://use.typekit.net/af/0f0c62/00000000000000003b9aee62/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/0f0c62/00000000000000003b9aee62/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/0f0c62/00000000000000003b9aee62/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'chaparral-pro-display';
  src: url('https://use.typekit.net/af/3c06e5/00000000000000003b9aee5d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/3c06e5/00000000000000003b9aee5d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/3c06e5/00000000000000003b9aee5d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'chaparral-pro-display';
  src: url('https://use.typekit.net/af/f7f12e/00000000000000003b9aee5e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/f7f12e/00000000000000003b9aee5e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/f7f12e/00000000000000003b9aee5e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'chaparral-pro-display';
  src: url('https://use.typekit.net/af/b1e17c/00000000000000003b9aee61/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/b1e17c/00000000000000003b9aee61/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/b1e17c/00000000000000003b9aee61/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}
hr {
  @apply text-slate-300 !important;
}
.tk-apertura {
  font-family: 'apertura', sans-serif;
}
.tk-apertura-condensed {
  font-family: 'apertura-condensed', sans-serif;
}
.tk-chaparral-pro {
  font-family: 'chaparral-pro', serif;
}
.tk-chaparral-pro-caption {
  font-family: 'chaparral-pro-caption', serif;
}
.tk-chaparral-pro-subhead {
  font-family: 'chaparral-pro-subhead', serif;
}
.tk-chaparral-pro-display {
  font-family: 'chaparral-pro-display', serif;
}

@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
* {
  @apply font-base;
  @apply leading-loose;
  line-height: 1.3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading;
  @apply leading-loose;
}

input::placeholder {
  @apply font-base;
  @apply leading-loose;
}

/** Floating labels */
/* TextInput Component*/
input:focus:not([type='checkbox']):not(.alt):not(.location) ~ label,
input:not(:placeholder-shown):not([type='checkbox']):not(.alt):not(.location)
  ~ label,
input:-webkit-autofill:not([type='checkbox']):not(.alt):not(.location) ~ label {
  @apply left-0 text-xs font-semibold -translate-y-10;
}
input:disabled,
div:has(> input:disabled) {
  @apply cursor-not-allowed;
}
input:disabled ~ label,
div:has(> input:disabled) ~ label {
  @apply text-slate-400 cursor-not-allowed;
}
/* AltTextInput Component*/
/* Move the label up */
.phone ~ label,
input:focus:not([type='checkbox']).alt ~ label,
input:not(:placeholder-shown):not([type='checkbox']):not(.location) ~ label,
input:-webkit-autofill:not([type='checkbox']):not(.location) ~ label,
.birthYear input ~ label {
  @apply left-0 text-xs font-semibold -translate-y-3;
}

input:invalid:not([type='checkbox']) ~ label,
input:invalid(:placeholder-shown):not([type='checkbox']) ~ label {
  @apply text-red;
}

button:disabled {
  @apply cursor-not-allowed;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/** Input Code Verificatoin */
.styles_react-code-input__CRulA > input {
  @apply font-heading border-2 border-purple !important;
}

@keyframes slidebg {
  to {
    background-position: 50vw;
  }
}

@keyframes fadeIn {
  0% {
    display: block;
    @apply opacity-0;
  }
  100% {
    @apply opacity-100;
  }
}
@keyframes fadeOut {
  0% {
    @apply opacity-100;
  }
  100% {
    @apply opacity-0;
    display: none;
  }
}

.swiper-wrapper {
  @apply z-0 !important;
}

.rich-text ul {
  @apply pl-8 list-disc;
}

.rich-text a {
  @apply underline;
}

.rich-text ol {
  @apply list-decimal;
}
.rich-text p {
  @apply mb-4;
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.filter {
  @apply relative stroke-gray1;
}
.filter p {
  @apply font-bold text-gray1;
}
.filter[aria-expanded='true'],
.filter:hover {
  @apply bg-filter-selected stroke-white;
}
.filter[aria-expanded='true'] p,
.filter:hover p {
  @apply text-white;
}

.filter[aria-expanded='true']:before,
.career-filter[aria-expanded='true']:before,
filter:hover:before {
  content: '';
  position: absolute;
  top: calc(100% - 15px);
  left: calc(50% - 8px);
  border-top: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid white;
  border-left: 8px solid transparent;
  z-index: 200;
}

.results .program-card-wrapper {
  @apply md:hover:scale-program-card;
}
.results .program-card-wrapper:hover .program-card {
  @apply md:h-auto md:z-[8];
}
.results .program-card-wrapper .content {
  @apply md:grid md:grid-rows-3;
}

.results .program-card-wrapper:hover .content {
  @apply md:h-auto md:flex md:flex-col md:justify-evenly;
}
.results .program-card-wrapper:hover .middle-row {
  @apply md:my-2;
}
.results .program-card-wrapper .title {
  @apply md:truncate;
}

.other-programs .program-card-wrapper .title {
  @apply md:truncate;
}

.other-programs .program-card-wrapper .middle-row {
  @apply my-2;
}

.results .program-card-wrapper .middle-row {
  @apply my-2 md:my-0;
}

.results .program-card-wrapper:hover .title {
  @apply overflow-auto whitespace-normal md:line-clamp-3;
}

.Toastify .skillup {
  --toastify-icon-color-success: #063347;
  --toastify-color-progress-success: #063347;
  --toastify-icon-color-error: #feca67;
  --toastify-color-progress-error: #feca67;
}

.Toastify .wherewego {
  --toastify-icon-color-success: #00d2a9;
  --toastify-color-progress-success: #00d2a9;
  --toastify-icon-color-error: #feca67;
  --toastify-color-progress-error: #feca67;
}
.Toastify .wherewego * {
  @apply font-base;
}
.career-filter[aria-expanded='true'] {
  @apply bg-careerPageHighlight;
}

.career-card-wrapper:hover {
  @apply md:scale-program-card;
}

.career-card-wrapper:hover .card-title {
  @apply whitespace-normal md:overflow-auto md:line-clamp-2 md:text-ellipsis;
}

.job-zones .top,
.job-zones .middle,
.job-zones .bottom {
  @apply bg-careerPageHighlight border-careerPageHighlight;
}

.job-zones .top:after,
.job-zones .middle:after {
  /* background-color: black; */
  @apply bg-careerPageHighlight;
}

.job-zones .top.bottom:after {
  @apply bg-dark-gray/20;
}

.styles_react-code-input__CRulA > input {
  max-width: calc(14vw - 0.5rem);
}

/* CUSTOM STYLES FOR RANGE SLIDER /=*/
input[type='range'] {
  @apply relative w-full bg-transparent appearance-none;
}

input[type='range']::-webkit-slider-thumb {
  @apply appearance-none;
}

input[type='range']:focus {
  @apply outline-none;
}

input[type='range']::-ms-track {
  @apply w-full text-transparent bg-transparent border-transparent cursor-pointer;
}

input[type='range']::-webkit-slider-thumb {
  @apply appearance-none h-[34px] w-[34px] bg-white cursor-pointer mt-[-14px] shadow-sm rounded-full bg-primary z-10;
}
input[type='range']::-moz-range-thumb {
  @apply appearance-none h-[34px] w-[34px] bg-white cursor-pointer mt-[-14px] shadow-sm rounded-full bg-primary z-10;
}
input[type='range']::-ms-thumb {
  @apply appearance-none h-[34px] w-[34px] bg-white cursor-pointer mt-[-14px] shadow-sm rounded-full bg-primary z-10;
}

input[type='range']::-webkit-slider-runnable-track {
  @apply h-[9px] md:h-[5px] cursor-pointer bg-transparent;
}

input[type='range']::-moz-range-track {
  @apply h-[9px] md:h-[5px] cursor-pointer bg-transparent;
}

input[type='range']::-ms-track {
  @apply h-[9px] md:h-[5px] cursor-pointer bg-transparent;
}
input[type='range']::-ms-fill-lower {
  @apply h-[9px] md:h-[5px] cursor-pointer bg-transparent;
}
input[type='range']::-ms-fill-upper {
  @apply h-[9px] md:h-[5px] cursor-pointer bg-transparent;
}

/* FILTER OPTOINS */
.filter-options {
  @apply w-80;
}

.filter-options.salary {
  @apply w-[572px];
}

/* Dallas */
.container {
  @apply px-4;
}

/** React Datepicker */
.react-datepicker__input-container {
  @apply h-full opacity-0;
}

.react-datepicker__input-container input {
  @apply w-full h-full;
}

.react-datepicker-wrapper {
  @apply h-full;
}

.react-datepicker-popper {
  @apply w-[300px] md:ml-[-80px] !important;
}

.react-datepicker__triangle {
  @apply hidden;
}

.react-datepicker__navigation-icon::before {
  @apply border-primary-dark top-[20px] !important;
}
.react-datepicker-year-header {
  @apply font-base font-medium text-primary-dark bg-white py-4  !important;
}

.react-datepicker__year-wrapper {
  @apply max-w-none !important;
}

.react-datepicker__year-text {
  @apply font-base font-medium text-gray7 text-base py-2 !important;
}
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__year-text--selected {
  @apply bg-primary text-white !important;
}
.react-datepicker__year-text.react-datepicker__year-text--disabled {
  @apply text-transparent !important;
}
.react-datepicker__navigation {
  @apply h-[52px] items-stretch !important;
}

/* program grid */
.program-grid {
  @apply grid grid-cols-1;
}
@media screen and (min-width: 1024px) {
  .program-grid {
    grid-template-columns: 1fr calc(1024px - 32rem) 32rem 1fr;
  }
}

@media screen and (min-width: 1280px) {
  .program-grid {
    grid-template-columns: 1fr calc(1280px - 32rem) 32rem 1fr;
  }
}

@media screen and (min-width: 1536px) {
  .program-grid {
    grid-template-columns: 1fr calc(1536px - 32rem) 32rem 1fr;
    grid-template-rows: calc(100vh - 200px) auto;
  }
}

/* WELCOME PATH TRIANGLE*/
/* .triangle {
  @apply mt-7 before:content-[''] before:absolute before:border-[15px] before:border-l-pink before:border-b-pink before:border-t-transparent before:border-r-transparent before:top-[2px] before:left-[40px];
} */

/* BirthYear*/
.birthYear input,
.birthYear label {
  @apply text-center;
}

.birthYear label {
  @apply w-full text-center;
}

/* .work-style-quiz-question:nth-child(odd) {
  @apply bg-questionsBg;
} */

.quiz-results > div {
  @apply bg-primary-dark;
}

.tasks-swiper .swiper-slide {
  @apply bg-[#195161C6];
}
.tasks-swiper .swiper-slide-next,
.tasks-swiper .swiper-slide-prev {
  @apply bg-[#195161];
}
.tasks-swiper .swiper-slide-visible {
  @apply bg-primary-dark;
}

.banner-heading * {
  @apply font-bold;
}

.pre-footer h2 p {
  @apply leading-[30px] tracking-wide;
}

h2 p a,
h3 p a {
  @apply underline;
}
.pre-footer h3 p {
  @apply leading-[22px] tracking-wide;
}

@import './loader.css';

.job-body h5,
.job-body h1,
.job-body h2,
.job-body h3,
.job-body h4,
.job-body h6 {
  @apply my-4 text-xl font-bold font-heading;
}

.welcome-path-slide-header {
  @apply my-8 text-xl font-bold leading-normal text-center  font-heading text-dark md:text-2xl;
}

.jobs-input {
  @apply font-base py-2 w-full lg:w-[300px] pr-20 truncate md:pr-8 border-solid rounded-[5px] border-gray border-1 mr-4 outline-none;
}

.video {
  @apply max-w-full rounded-tl-xl rounded-tr-xl max-h-[143px] sm:max-h-[207px] lg:max-h-[220px] object-cover;
}
.video .react-player__preview {
  @apply rounded-tl-xl rounded-tr-xl;
}

.video-overlay {
  background: transparent
    linear-gradient(180deg, rgba(1, 49, 69, 0.2) 0%, rgba(1, 49, 69, 0.5) 100%)
    0% 0% no-repeat padding-box;
}

[data-floating-ui-portal] span {
  @apply border-profileButtons;
}

.milestone-marker {
  @apply after:bg-gray12 after:w-[8px] after:content-[''] after:block  after:mx-auto after:transform after:translate-y-[26px];
}

.more-activities .swiper-wrapper {
  @apply flex items-center;
}

.banner-rich-text a {
  @apply underline;
}
