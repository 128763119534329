
/* LAYOUT */
.loader__container {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  text-align: center;
  height: 100%;
}
.loader__row {
  margin: 1rem 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center
}
.loader__box {
  display: inline-block;
  width: 23%;
}
.loader__box margin 1rem {
  background-color: #eee;
}
.loader__box margin 1rem:first-of-type {
  margin-left: 0;
}
/* CLEARFIX */
.cf {
  *zoom: 1;
}
.cf:before,
.cf:after {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}
/* ALL LOADERS */
[id|=loader] {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}
/* LOADER 2 */
#loader-2 span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 0%;
  background-color: #bfa0d4;
  margin: 35px 5px;
}
#loader-2 span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}
#loader-2 span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}
#loader-2 span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}
@-moz-keyframes bounce {
  0%, 75%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px);
  }
}
@-webkit-keyframes bounce {
  0%, 75%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px);
  }
}
@-o-keyframes bounce {
  0%, 75%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px);
  }
}
@keyframes bounce {
  0%, 75%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px);
  }
}